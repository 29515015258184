const swiper = new Swiper('.swiper-container', {
  slidesPerView: 4,
  spaceBetween: 40,
  speed: 300,
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: true,
  },

  breakpoints: {
    600: {
      slidesPerView: 6,
      spaceBetween: 40,
    },
    1440: {
      slidesPerView: 6,
      spaceBetween: 80,
    }
  }
});